import axios from "axios";
import { toast } from "react-toastify";
import { store } from "../store";

const domain = window.location.host.split(".")[0];
let instance = null;
let Headers = {
    "Content-Type": "application/json",
    domain_name: "",
    apiKey: "D2BDF6499CD925AB4DAF513BE589F",
    apiSecret: "F6D9851417DBA8492E25F6E6B43E9",
};
if (window.location.host.includes("localhost")) {
    if (window.location.hash.includes("/login"))
        instance = axios.create({ timeout: 1000 * 300, headers: { ...Headers } });
    else
        instance = axios.create({
            timeout: 1000 * 300,
            // headers: { ...Headers, domain_name: "yoindi" },
            // headers: { ...Headers, domain_name: "bixolabs" },

            // headers: { ...Headers, domain_name: "hellopartner" },
            // headers: { ...Headers, domain_name: "creditbuddy" },
            // headers: { ...Headers, domain_name: "finance" },
            // headers: { ...Headers, domain_name: "arpit" },
            // headers: { ...Headers, domain_name: "ambujtest" },
            // headers: { ...Headers, domain_name: "yoindi" },
            // headers: { ...Headers, domain_name: "vityam" },
            // headers: { ...Headers, domain_name: "arpitsaxena385" },
            // headers: { ...Headers, domain_name: "1clickcapital" },
            // headers: { ...Headers, domain_name: "recapitafinance" },
        });
} else {
    instance = axios.create({
        timeout: 1000 * 300,
        headers: { ...Headers, domain_name: domain === "lenders" ? "" : domain },
    });
}

/**
 * Request interceptor
 * Add Authorization header if it exists
 * This configuration applies for all requests
 */
instance.interceptors.request.use(
    (reqConfig) => {
        // const access_token = getCookie("token");
        const access_token = store.getState().auth.token;
        let domain_name = "";

        // for http://localhost:3000
        if (window.location.host.includes("localhost")) {
            // for /dashboard
            if (access_token) {
                domain_name = store.getState().auth.domain_name;
            }
            // for /login
            else {
                // domain_name = "lsptesting"; // need to manually change this when using in local
                // domain_name = "networkLSP";
                // domain_name = "networkci"
                // domain_name = "roopyalsp";
                // domain_name = "stellarinvestments";
                // domain_name = "narutoci";
                // domain_name = "yoindi";
                // domain_name = "maxemocapital";
                // domain_name = "myfindoc";
                // domain_name = "maxemocapital";
                // domain_name = "capup";
                // domain_name = "myfindoc";
                // domain_name = "businessmaster";
                // domain_name = "account";
                // domain_name = "ampirefinance";
                // domain_name = "roopyaci";
                // domain_name = "roopyacidemo";
                //domain_name = "paschimfintech";
                // domain_name = "businesstesting";
                // domain_name = "roopyacidemo";
                // domain_name = "uttkarsh";
                // domain_name = "deblin1";
                // domain_name = "uttkarsh";
                // domain_name = "newLSPTest";
                // domain_name = "newEmail";
                // domain_name = "indusind"
                // domain_name = "credmudra";
                // domain_name = "credmudra";
                // domain_name = "citesting"
                // domain_name = "eazycredit1in";
                // domain_name = "ciroopyanetwork";
                // domain_name = "deblin1chaudhuri";
                // domain_name = "myfindoc";
                // domain_name = "recapitafinance";
                // domain_name = "kashti";
                // domain_name = "iflcorp";
                // domain_name = "desonmarketing";
                // domain_name = "businessmaster";
                // domain_name = "lenderpolicy";
                // domain_name = "customer1master";
                domain_name = "finture";
            }
        }

        // for https://lenders.roopya.com (for CEO)
        else if (domain === "lenders") {
            // for /dashboard
            if (access_token) {
                domain_name = "";
            }
            // for /login
            else {
                domain_name = ""; // no need to pass domain_name for ceo in login page
            }
        }
        // for https://yoindi.roopyadev.in (for users)
        else if (domain !== "lenders") {
            // for /dashboard
            if (access_token) {
                domain_name = domain;
            }
            // for /login
            else {
                domain_name = domain; // need to pass domain_name for user in login page
            }
        }

        if (!reqConfig.headers) {
            reqConfig.headers = {};
        }
        reqConfig.headers.Authorization = `Bearer ${access_token || ""}`;
        reqConfig.headers.domain_name = domain_name;
        reqConfig.timeout = 3000000;

        return reqConfig;
    },
    (error) => {
        return Promise.reject(error);
    }
);

/**
 * Response interceptor
 * Catch basic errors like 401 and redirect to login
 * This configuration applies for all responses
 */
instance.interceptors.response.use(
    (response) => response,
    (error) => {
        // Do something with response error
        if (typeof error === "undefined") {
            // request cancelled
            // when backend server is not available at all
            //   store.dispatch(
            //     updateAlertMsg({
            //       message:
            //         "Backend services are not active. Please try again after sometime",
            //       messageType: "warning",
            //     })
            //   );
            //   "Backend services are not active. Please try again after sometime"
            // );
        } else if (typeof error.response === "undefined") {
            // when request is timeout
        } else if (error.response.status === 401) {
            // apply refresh token logic here instead of redirecting to login
            // localStorage.clear();
            // localStorage.clear();
            // deleteAllCookies();
            // store.dispatch(push("/login"));
            // alert(error);
        } else if (error.response.status === 400) {
            toast.error(error.response?.data?.message, {
                toastId: error.response?.data?.message,
                autoClose: false,
            });
        }

        return Promise.reject(error);
    }
);

export default instance;
